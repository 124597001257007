<template>
  <v-container class="ml-5 mr-5 my-5">
    <v-layout row wrap>
        <v-form name="register-form" ref="registerUserForm" autocomplete='off' v-model="isFormVisiblyValid" lazy-validation>
          <v-card class="mb-5" flat>
            <v-text-field
              label="原密码"
              type="password"
              name="oldPassword"
              :rules ="[required]"
              v-model="oldPassword"
            ></v-text-field>
            <v-text-field
              label="新密码"
              type="password"
              name="newPassword"
              hint="至少6位，仅可以使用数字及大小写字母"
              :rules ="newPasswordRules"
              v-model="newPassword"
            ></v-text-field>
            <v-text-field
              label="确认密码"
              type="password"
              name="newPasswordConfirm"
              :rules ="newPasswordConfirmRules"
              v-model="newPasswordConfirm"
            ></v-text-field>
          </v-card>
          <v-btn color="primary" :disabled="!isFormVisiblyValid || requestSent" @click="changePassword(oldPassword, newPassword)">
            修改密码
          </v-btn>
        </v-form>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
    >
      {{ snackbarMsg }}
      <v-btn
        color="primary"
        text
        @click="snackbar = false"
      >
        知道了
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'
import consola from 'consola'

export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
      isFormVisiblyValid: true,
      oldPassword: '',
      required: (value) => !!value || '必填',
      newPassword: '',
      newPasswordRules: [
        v => /^[a-zA-Z0-9]{6,16}$/.test(v) || '密码只能包含数字及大小写字母，并为6-16位'
      ],
      newPasswordConfirm: '',
      newPasswordConfirmRules: [
        v => v === this.newPassword || '密码两次输入不一致'
      ],
      requestSent: false,
      snackbar: false,
      snackbarTimeout: 4000,
      snackbarMsg: ''
    }
  },
  async mounted () {
    consola.info(`#Page: password-change.`)
  },
  methods: {
    async changePassword (oldPassword, newPassword) {
      this.requestSent = true
      try {
        const res = await AuthenticationService.changePassword(oldPassword, newPassword)
        this.showSnackBar(res.data.msg, this.snackbarTimeout)
        setTimeout(() => {
          this.$router.push('account')
        }, this.snackbarTimeout)
      } catch (error) {
        consola.log(`error: ${JSON.stringify(error.response)}`)
        this.showSnackBar(error.response.data.msg, this.snackbarTimeout)
        this.requestSent = false
      }
    },
    showSnackBar (snackbarMsg, timeout) {
      this.snackbar = true
      this.snackbarTimeout = timeout
      this.snackbarMsg = snackbarMsg
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
